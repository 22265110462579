<template>
  <div class="component-wrap">
    <div class="component-tit">我的知识</div>
    <div class="filters" id="pageTop">
      <ListFilter name="类型" :dataSource="fileType" @onChange="typeChange">
        <template v-slot:right>
          <div class="search">
            <a-input-search
              v-model:value="key"
              placeholder="请输入名称"
              style="width: 240px"
              @search="onSearch"
            />
          </div>
        </template>
      </ListFilter>
      <ListFilter
        only
        name="状态"
        :dataSource="reviewStatus"
        @onChange="switchStatus"
      />
    </div>
    <template v-if="dataList.length">
      <div class="list">
        <div
          class="item fl"
          v-for="(item, index) in dataList"
          :key="index"
          @click="clickJump(item)"
        >
          <div class="icon">
            <img
              :src="require(`@/assets/image/fileType/${item.fileType}.png`)"
              alt="icon"
            />
          </div>
          <div class="info">
            <div class="title">
              <a-tooltip>
                <template #title>{{ item.fileName }}</template>
                {{ item.fileName }}
              </a-tooltip>
            </div>
            <div class="size">
              <template v-if="item.fileType == 1">
                视频时长：{{
                  formatTime(item.times + 1, { h: ":", m: ":", s: "" })
                }}
              </template>
              <template v-else-if="item.fileType == 2">
                音频时长：{{
                  formatTime(item.times + 1, { h: ":", m: ":", s: "" })
                }}
              </template>
              <template v-else-if="item.fileType == 3">
                PDF页数：{{ item.pages }}P
              </template>
              <template v-else>
                文件大小：{{ getFileSize(item.fileSize) }}
              </template>
            </div>
            <div class="progress">
              <a-progress
                :showInfo="false"
                :strokeWidth="4"
                strokeColor="#266FFF"
                trailColor="#EEEEEE"
                :percent="item.progress"
              />
            </div>
            <div class="time">
              最后学习时间：{{ dateFormat(item.lastTime) }}
            </div>
          </div>
          <div class="num" :class="{ complete: item.progress == 100 }">
            {{ item.progress }}%
          </div>
        </div>
      </div>
      <div class="page-Wrap">
        <a-pagination
          show-quick-jumper
          hideOnSinglePage
          :defaultPageSize="20"
          v-model:current="currentPage"
          :total="pageTotal"
          @change="pageChange"
        >
          <template #itemRender="{ type, originalElement }">
            <a class="page-a" v-if="type === 'prev'">上一页</a>
            <a class="page-a" v-else-if="type === 'next'">下一页</a>
            <renderVNode v-else :vnode="originalElement"></renderVNode>
          </template>
        </a-pagination>
      </div>
    </template>
    <a-empty v-else style="padding: 60px 0" />
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useStore } from "vuex";
import {
  dateFormat,
  getFileSize,
  formatTime,
  formatSeconds,
} from "@/utils/tools";
import { fileType } from "@/utils/business";
import { getDocList } from "@/api/user";
import ListFilter from "@/components/filter/ListFilter.vue";
const renderVNode = (_, { attrs: { vnode } }) => vnode;
export default {
  components: {
    ListFilter,
    renderVNode,
  },
  setup() {
    const store = useStore();
    const state = reactive({
      reviewStatus: [
        { id: 1, name: "已阅读" },
        { id: 2, name: "未读完" },
        { id: 3, name: "未阅读" },
      ],
      dataList: [],
      loading: true,
      currentPage: 1,
      pageTotal: 1,
      key: "",
      status: 0,
      types: [],
    });

    // 重新加载list
    const reloadList = () => {
      state.dataList = [];
      state.currentPage = 1;
      state.loading = true;
      getList();
    };

    const pageChange = (n) => {
      state.currentPage = n;
      state.loading = true;
      getList(true);
    };

    const getList = (scroll = false) => {
      getDocList({
        page: state.currentPage,
        pageSize: 20,
        key: state.key,
        types: state.types,
        status: state.status,
      }).then((res) => {
        state.pageTotal = res.data.totals;
        state.dataList = res.data.list || [];
        state.loading = false;
        if (scroll) {
          setTimeout(() => {
            document.getElementById("pageTop").scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }, 0);
        }
      });
    };

    const clickJump = (item) => {
      let urlStr = `/doc/detail?id=${item.documentId}`;
      if (store.getters.companyInfo.useNewWindow == 1) {
        window.open(urlStr + "&ddtab=true");
      } else {
        window.location.href = urlStr;
      }
    };

    const typeChange = (arr) => {
      state.types = arr[0] === 0 ? [] : arr;
      reloadList();
    };

    const switchStatus = (arr) => {
      state.status = arr[0];
      reloadList();
    };

    const onSearch = (value) => {
      state.currentPage = 1;
      reloadList();
    };

    getList();

    return {
      dateFormat,
      getFileSize,
      formatTime,
      formatSeconds,
      fileType,
      ...toRefs(state),
      switchStatus,
      pageChange,
      clickJump,
      typeChange,
      onSearch,
    };
  },
};
</script>

<style lang="less" scoped>
.component-wrap {
  min-height: 100%;
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);
  .component-tit {
    font-size: 18px;
    line-height: 18px;
    color: #333;
    font-weight: bold;
    padding-left: 6px;
    margin: 4px 0 10px;
    border-left: 6px solid @color-theme;
  }
  .filters {
    margin-bottom: 10px;
    .search {
      .mixinFlex(flex-end);
    }
    .filter {
      border-bottom: none;
      padding-bottom: 0;
      &:last-child {
        padding-bottom: 14px;
      }
    }
  }
  .list {
    display: inline-block;
    .item {
      position: relative;
      width: 431px;
      height: 118px;
      padding: 14px 24px;
      cursor: pointer;
      background-color: #f5f5f6;
      margin-right: 22px;
      margin-bottom: 22px;
      border-radius: 4px;
      .mixinFlex(space-between);
      .icon {
        .mixinImgWrap(68px; 90px);
      }
      .info {
        width: calc(100% - 152px);
        .title {
          font-size: 14px;
          .mixinEllipsis(22px);
        }
        .size {
          font-size: 12px;
          color: #a7a7a7;
          margin-top: 2px;
        }
        .time {
          margin-top: 6px;
          font-size: 12px;
          color: #a7a7a7;
        }
      }
      .num {
        width: 60px;
        text-align: right;
        color: #202020;
        font-size: 20px;
        font-family: DIN-Medium, DIN;
        &.complete {
          color: #266fff;
        }
      }
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}

.page-Wrap {
  .page-a {
    padding: 0 4px;
    height: 32px;
    display: inline-block;
  }
  ::v-deep(.ant-pagination-item) {
    border: none;
  }
  ::v-deep(.ant-pagination-item-active) {
    background-color: @color-theme;
    a {
      color: #fff !important;
    }
  }
}
</style>
